<template>
    <form class="needs-validation"
            novalidate>
            <header>
                <div class="container">   
                <div class="row"
                    style="margin: 0;">
                    <div class="col-6"
                        align="left">
                        <img :src="checkoutDetails.checkout?.logo ?? 'https://cdn.hoopay.com.br/images/logo/logo-brand-dark.png'"
                            style="height: 35px" />
                    </div>

                    <div class="col-6"
                        align="right"
                        style="color: #737380">
                        Compra Segura
                        <img src="https://cdn.hoopay.com.br/images/checkout/secure_checkout.png"
                            height="15" />
                    </div>

                    <nav v-if="checkoutType == 'stepByStep'">
                        <ul>
                            <li class="active">
                                <button @click.prevent>1</button>
                            </li>
                            <span :class="{active: step >= 2}"></span>
                            <li :class="{active: step >= 2}">
                                <button @click.prevent>2</button>
                            </li>
                            <span :class="{active: step == 3}"></span>
                            <li :class="{active: step == 3}">
                                <button @click.prevent>3</button>
                            </li>
                        </ul>
                    </nav> 
                    <!-- <img class="pt-4" src="https://aws-assets.kiwify.com.br/cdn-cgi/image/fit=scale-down,width=1000/LkDihPv3zqxb4PX/img_builder_d46211cb-e1bd-426d-829f-b739734c8269_b7c7be81eab8450e8e232b3f72fc3b9b.png" alt=""> -->
                    <div class="col-md-12 items_list" v-if="(checkoutType == 'stepByStep' && step == 3) || checkoutType == 'allSteps'">
                        <h3 class="section_title">
                            Carrinho
                        </h3>

                        <div class="col-12"
                            style="margin-bottom: 10px;">
                            <div class="row" v-for="product in checkoutDetails.products">
                                <div class="col-2 cart_content center">
                                    <img :src="product.image ? product.image: 'https://cdn.hoopay.com.br/images/checkout/no-image.png'"
                                        style="max-height: 60px;">
                                </div>
                                <div class="col-10 ml-3 cart_content">
                                    <div class="row show-cart">
                                        <p>{{ product.title }}</p>
                                        <p>{{ product.promotionalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p class="d-flex justify-content-end">
                            <a class="arrow-icon open"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseExample"
                                aria-expanded="false"
                                aria-controls="collapseExample">
                                <span class="left-bar"></span>
                                <span class="right-bar"></span>
                            </a>
                        </p>

                        <div class="collapse"
                            id="collapseExample">
                            <table width="100%"
                                border="0">
                                <tfoot>
                                    <tr>
                                        <th style="text-align: right;">SubTotal<br><span id="amount_total">{{ checkoutDetails.amount?.subtotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span></th>
                                    </tr>

                                    <tr>
                                        <th style="text-align: right;">Desconto<br><span id="amount_total">{{ checkoutDetails.amount?.discount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span></th>
                                    </tr>
                                    <tr>
                                        <th style="text-align: right;">Frete<br><span id="amount_total">{{ checkoutDetails.amount?.shipping.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                                        </th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <table width="100%"
                            border="0">
                            <tfoot>
                                <tr>
                                    <th style="text-align: right;">Total<br><span id="amount_total">{{ checkoutDetails.amount?.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </header>
        
        <div class="d-flex form-body">
            <div class="container">
                <div class="content" v-if="(checkoutType == 'stepByStep' && step == 3) || checkoutType == 'allSteps'">
                    <div class="row">
                        <h3 class="section_title">
                            <div class="dot">3</div> Forma de Pagamento
                        </h3>
    
                        <div class="panel with-nav-tabs panel-default">
                            <div class="panel-heading">
                                <ul class="nav nav-tabs">
                                    <li class="box_method_payment button_method_payment button_credit_card col-md" :class="payload.paymentMethod == 'creditCard' ? 'active' : ''" @click="payload.paymentMethod = 'creditCard'">
                                        <a>
                                            <img
                                                src="https://cdn.hoopay.com.br/images/checkout/credit_card.png"
                                                height="25"> 
                                            Cartão de Crédito
                                        </a>
                                    </li>
                                    <li class="box_method_payment button_method_payment button_pix col-md" :class="payload.paymentMethod == 'pix' ? 'active' : ''" @click="payload.paymentMethod = 'pix'"><a><img
                                                src="https://cdn.hoopay.com.br/images/checkout/pix.png"
                                                height="25"> Pix</a></li>
                                    <li class="box_method_payment button_method_payment button_billet col-md" :class="payload.paymentMethod == 'billet' ? 'active' : ''"
                                    @click="payload.paymentMethod = 'billet'">
                                        <a>
                                            <img src="https://cdn.hoopay.com.br/images/checkout/billet.png" height="25"> 
                                            Boleto
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="panel-body">
                                <div class="tab-content">
                                    <div class="tab-pane fade methods_payment box_credit_card" :class="payload.paymentMethod == 'creditCard' ? 'in active' : 'fade'"
                                        id="tab1default">
                                        <div class="tabs effect-3">
                                            <!-- tab-title -->
                                            <input type="radio"
                                                id="tab-1"
                                                name="tab-effect-3"
                                                checked @change="($event.target as HTMLInputElement).checked ? cardsQuantity = 1 : cardsQuantity = 2">
                                            <span>Pagar com UM cartão</span>
    
                                            <input type="radio"
                                                id="tab-2"
                                                name="tab-effect-3" @change="($event.target as HTMLInputElement).checked ? cardsQuantity = 2 : cardsQuantity = 1">
                                            <span>Pagar com DOIS cartões</span>
    
    
                                            <div class="line ease"></div>
    
                                            <br><br><br>
    
                                            <!-- tab-content -->
                                            <div class="tab-content">
                                                <section id="tab-item-1" v-if="cardsQuantity == 1">
                                                    <div class="col-md-6">
                                                        <div class="cc[0] row">
                                                            <div class="col-md-12">
                                                                <label for="cc_number[0]">
                                                                    <input type="tel"
                                                                        name="cc_number[0]"
                                                                        id="cc_number[0]"
                                                                        class="form-control"
                                                                        placeholder=" "
                                                                        required v-maska="'#### #### #### ####'"  v-model="payload.ccards[0].number">
                                                                    <span>Número do Cartão*</span>
                                                                </label>
                                                            </div>
    
                                                            <div class="col-md-12">
                                                                <label for="cc_holder[0]">
                                                                    <input type="text"
                                                                        name="cc_holder[0]"
                                                                        id="cc_holder[0]"
                                                                        class="form-control" 
                                                                        placeholder=" "
                                                                        required  v-model="payload.ccards[0].holder">
                                                                    <span>Nome impresso no cartão*</span>
                                                                </label>
                                                            </div>
    
                                                            <div class="col-md-6">
                                                                <label for="cc_expiryMonth[0]">
                                                                    <select type="text"
                                                                        name="cc_expiryMonth[0]"
                                                                        id="cc_expiryMonth[0]"
                                                                        class="form-control"
                                                                        placeholder=" "
                                                                        required v-model="payload.ccards[0].expiryMonth">
                                                                        <option v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :value="i" :disabled="payload.ccards[0].expiryYear == new Date().getFullYear() && i < (new Date().getMonth() + 1)">{{ i }}</option>
                                                                    </select>
    
                                                                    <span>Mês de Vencimento*</span>
                                                                </label>
                                                            </div>
    
                                                            <div class="col-md-6">
                                                                <label for="cc_expiryYear[0]">
                                                                    <select type="text"
                                                                        name="cc_expiryYear[0]"
                                                                        id="cc_expiryYear[0]"
                                                                        class="form-control"
                                                                        placeholder=" "
                                                                        required v-model="payload.ccards[0].expiryYear">
                                                                        <option v-for="i in [0,1,2,3,4,5,6,7,8,9,10,]" :value="new Date().getFullYear() + i">{{ new Date().getFullYear() + i }}</option>
                                                                    </select>
    
                                                                    <span>Ano de Vencimento*</span>
                                                                </label>
                                                            </div>
    
                                                            <div class="col-md-12">
                                                                <label for="cc_securityCode[0]">
                                                                    <input type="tel"
                                                                        name="cc_securityCode[0]"
                                                                        id="cc_securityCode[0]"
                                                                        pattern="/^-?\d+\.?\d*$/"
                                                                        onKeyPress="if(this.value.length==4) return false;"
                                                                        class="form-control"
                                                                        placeholder=" "
                                                                        required v-maska="'####'" v-model="payload.ccards[0].cvv">
                                                                    <span>Cód. de Segurança (CVV)*</span>
                                                                </label>
                                                            </div>
    
                                                            <div class="col-md-12">
                                                                <label for="cc_installments[0]">
                                                                    <select type="text"
                                                                        name="cc_installments[0]"
                                                                        id="cc_installments[0]"
                                                                        class="form-control"
                                                                        placeholder=" "
                                                                        required v-model="payload.ccards[0].installments">
                                                                        <option :value="installment.installment" v-for="(installment, i) in checkoutDetails.installments">{{ installment.installment }}x de {{ installment.installmentAmount.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' }) }} ({{ installment.textInstallmentsInterest }})</option>
                                                                    </select>
                                                                    <span>Parcelas*</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                    <div class="col-md-12">
                                                        <div class="cc_display[0]"></div>
                                                    </div>
                                                </section>
    
                                                <section id="tab-item-2" v-if="cardsQuantity == 2">
                                                    <div class="row">
                                                        <div class="col-md-6">
    
                                                            <div class="cc[0] row">
                                                                <div class="col-md-12">
                                                                    <label for="cc_number[0]">
                                                                        <input type="tel"
                                                                            name="cc_number[0]"
                                                                            id="cc_number[0]"
                                                                            class="form-control"
                                                                            placeholder=" "
                                                                            required v-maska="'#### #### #### ####'" v-model="payload.ccards[0].number">
                                                                        <span>Número do Cartão*</span>
                                                                    </label>
                                                                </div>
    
                                                                <div class="col-md-12">
                                                                    <label for="cc_holder[0]">
                                                                        <input type="text"
                                                                            name="cc_holder[0]"
                                                                            id="cc_holder[0]"
                                                                            class="form-control"
                                                                            placeholder=" "
                                                                            required v-model="payload.ccards[0].holder">
                                                                        <span>Nome impresso no cartão*</span>
                                                                    </label>
                                                                </div>
    
                                                                <div class="col-md-6">
                                                                    <label for="cc_expiryMonth[0]">
                                                                        <select type="text"
                                                                            name="cc_expiryMonth[0]"
                                                                            id="cc_expiryMonth[0]"
                                                                            class="form-control"
                                                                            placeholder=" "
                                                                            required v-model="payload.ccards[0].expiryMonth">
                                                                            <option v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :value="i" :disabled="payload.ccards[1].expiryYear == new Date().getFullYear() && i < (new Date().getMonth() + 1)">{{ i }}</option>
                                                                        </select>
    
                                                                        <span>Mês de Vencimento*</span>
                                                                    </label>
                                                                </div>
    
                                                                <div class="col-md-6">
                                                                    <label for="cc_expiryYear[1]">
                                                                        <select type="text"
                                                                            id="cc_expiryYear[1]"
                                                                            class="form-control"
                                                                            placeholder=" "
                                                                            required v-model="payload.ccards[0].expiryYear">
                                                                            <option v-for="i in [0,1,2,3,4,5,6,7,8,9,10,]" :value="new Date().getFullYear() + i">{{ new Date().getFullYear() + i }}</option>
                                                                        </select>
    
                                                                        <span>Ano de Vencimento*</span>
                                                                    </label>
                                                                </div>
    
                                                                <div class="col-md-12">
                                                                    <label for="cc_securityCode[0]">
                                                                        <input type="tel"
                                                                            name="cc_securityCode[0]"
                                                                            id="cc_securityCode[0]"
                                                                            class="form-control"
                                                                            placeholder=" "
                                                                            required v-maska="'####'" v-model="payload.ccards[0].cvv">
                                                                        <span>Cód. de Segurança*</span>
                                                                    </label>
                                                                </div>
    
                                                                <div class="col-md-12">
                                                                    <label for="cc_installments[0]">
                                                                        <select type="text"
                                                                            name="cc_installments[0]"
                                                                            id="cc_installments[0]"
                                                                            class="form-control"
                                                                            placeholder=" "
                                                                            required v-model="payload.ccards[0].installments">
                                                                            <option :value="installment.installment" v-for="(installment, i) in checkoutDetails.installments">{{ installment.installment }}x de {{ installment.installmentAmount.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' }) }} ({{ installment.textInstallmentsInterest }})</option>
                                                                        </select>
    
                                                                        <span>Parcelas*</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="cc[1] row">
                                                                <div class="col-md-12">
                                                                    <label for="cc_number[1]">
                                                                        <input type="tel"
                                                                            name="cc_number[1]"
                                                                            id="cc_number[1]"
                                                                            class="form-control"
                                                                            placeholder=" "
                                                                            required v-maska="'#### #### #### ####'" v-model="payload.ccards[1].number">
                                                                        <span>Número do Cartão*</span>
                                                                    </label>
                                                                </div>
    
                                                                <div class="col-md-12">
                                                                    <label for="cc_holder[1]">
                                                                        <input type="text"
                                                                            name="cc_holder[1]"
                                                                            id="cc_holder[1]"
                                                                            class="form-control"
                                                                            placeholder=" "
                                                                            required v-model="payload.ccards[1].holder">
                                                                        <span>Nome impresso no cartão*</span>
                                                                    </label>
                                                                </div>
    
                                                                <div class="col-md-6">
                                                                    <label for="cc_expiryMonth[1]">
                                                                        <select type="text"
                                                                            name="cc_expiryMonth[1]"
                                                                            id="cc_expiryMonth[1]"
                                                                            class="form-control"
                                                                            placeholder=" "
                                                                            required v-model="payload.ccards[1].expiryMonth">
                                                                            <option v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" :value="i" :disabled="payload.ccards[1].expiryYear == new Date().getFullYear() && i < (new Date().getMonth() + 1)">{{ i }}</option>
                                                                        </select>
    
                                                                        <span>Mês de Vencimento*</span>
                                                                    </label>
                                                                </div>
    
                                                                <div class="col-md-6">
                                                                    <label for="cc_expiryYear[1]">
                                                                        <select type="text"
                                                                            name="cc_expiryYear[1]"
                                                                            id="cc_expiryYear[1]"
                                                                            class="form-control"
                                                                            placeholder=" "
                                                                            required v-model="payload.ccards[1].expiryYear">
                                                                            <option v-for="i in [0,1,2,3,4,5,6,7,8,9,10,]" :value="new Date().getFullYear() + i">{{ new Date().getFullYear() + i }}</option>
                                                                        </select>
    
                                                                        <span>Ano de Vencimento*</span>
                                                                    </label>
                                                                </div>
    
                                                                <div class="col-md-12">
                                                                    <label for="cc_securityCode[1]">
                                                                        <input type="tel"
                                                                            name="cc_securityCode[1]"
                                                                            id="cc_securityCode[1]"
                                                                            class="form-control"
                                                                            placeholder=" "
                                                                            required v-maska="'####'" v-model="payload.ccards[1].cvv">
                                                                        <span>Cód. de Segurança*</span>
                                                                    </label>
                                                                </div>
    
                                                                <div class="col-md-12">
                                                                    <label for="cc_installments[1]">
                                                                        <select type="text"
                                                                            name="cc_installments[1]"
                                                                            id="cc_installments[1]"
                                                                            class="form-control"
                                                                            placeholder=" "
                                                                            required v-model="payload.ccards[1].installments">
                                                                            <option :value="installment.installment" v-for="(installment, i) in checkoutDetails.installments">{{ installment.installment }}x de {{ installment.installmentAmount.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' }) }} ({{ installment.textInstallmentsInterest }})</option>
                                                                        </select>
    
                                                                        <span>Parcelas*</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
    
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="tab-pane methods_payment box_billet row" :class="payload.paymentMethod == 'billet' ? 'in active' : 'fade'">
                                        <div class="payment-method__boxes" style="display: grid; grid-template-columns: 1fr 1fr; gap: 1rem">
                                            <div class="payment_box">
                                                <h4 class="text-primary h3">{{ checkoutDetails.amount?.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</h4>
                                                <div class="d-flex gap-2 align-items-center">
                                                    <svg style="min-width: 20px" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                                        <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M7.75 11.9999L10.58 14.8299L16.25 9.16992" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    <p class="m-0">Não é possível parcelar um boleto.</p>
                                                </div>
                                            </div>
                                            <div class="payment_box">
                                                <div><img src="https://cdn.hoopay.com.br/images/checkout/clock.png"
                                                        height="15" /></div>
                                                <div class="payment_box_title">Pague até a data de vencimento</div>
                                                Atente-se ao vencimento do boleto. Você pode pagar o boleto em qualquer
                                                banco ou
                                                casa
                                                lotérica
                                                até o dia do vencimento.
                                            </div>
                                            <div class="payment_box">
                                                <div><img src="https://cdn.hoopay.com.br/images/checkout/clock.png"
                                                        height="15" /></div>
                                                <div class="payment_box_title">Aguarde a aprovação do pagamento</div>
                                                Pagamentos com Boleto Bancário levam até 3 dias úteis para serem
                                                compensados e então
                                                terem
                                                os
                                                produtos liberados.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade methods_payment box_pix row" :class="payload.paymentMethod == 'pix' ? 'in active' : 'fade'"
                                        id="tab3default">
                                        <div class="payment-method__boxes" style="display: grid; grid-template-columns: 1fr 1fr; gap: 1rem">
                                            <div class="payment_box">
                                                <h4 class="text-primary h3">{{ checkoutDetails.amount?.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</h4>
                                                <div class="d-flex gap-2 align-items-center">
                                                    <svg style="min-width: 20px" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                                        <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M7.75 11.9999L10.58 14.8299L16.25 9.16992" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    <p class="m-0">Basta usar o aplicativo do seu banco para realizar um pagamento via PIX. É fácil e rápido!</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="payment_box">
                                                    <div><img src="https://cdn.hoopay.com.br/images/checkout/clock.png"
                                                            height="15" /></div>
                                                    <div class="payment_box_title">Pagamento Instantâneo</div>
                                                    Pagamentos com Pix são processados instantaneamente, porem em alguns
                                                    bancos pode
                                                    demorar
                                                    alguns
                                                    minutos!
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="order-bump">
                            <h3>LISTA COM 1000 FORNECEDORES NACIONAIS E AMERICANOS (EXCLUSIVO)</h3>
                            <div class="order-bump__content">
                                <label for="all-docs" class="check">
                                    <input type="checkbox" name="all-docs" id="all-docs">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none"><path d="M5.06761 9.95167C4.94632 10.0727 4.78194 10.1407 4.61057 10.1407C4.43919 10.1407 4.27482 10.0727 4.15352 9.95167L0.501349 6.2995C0.411146 6.20946 0.339583 6.10251 0.290756 5.98479C0.241929 5.86706 0.216797 5.74086 0.216797 5.61341C0.216797 5.48596 0.241929 5.35976 0.290756 5.24203C0.339583 5.1243 0.411146 5.01736 0.501349 4.92732L0.958393 4.47132C1.14038 4.2894 1.38716 4.1872 1.64448 4.1872C1.9018 4.1872 2.14858 4.2894 2.33057 4.47132L4.61057 6.74923L10.7671 0.592713C10.9491 0.410791 11.1959 0.308594 11.4532 0.308594C11.7105 0.308594 11.9573 0.410791 12.1393 0.592713L12.5963 1.04976C12.6865 1.1398 12.7581 1.24674 12.8069 1.36447C12.8557 1.48219 12.8809 1.60839 12.8809 1.73584C12.8809 1.86329 12.8557 1.98949 12.8069 2.10722C12.7581 2.22495 12.6865 2.33189 12.5963 2.42193L5.06761 9.95167Z" fill="white"></path>
                                        </svg>
                                    </span>
                                </label>
                                <img src="https://assets.kiwify.com.br/cdn-cgi/image/fit=scale-down,width=64/LkDihPv3zqxb4PX/IMG_0294_92c5bc7b85094ed5b23cff5a4ed965a8.jpeg" />
                                <p>⚠ 9 EM CADA 10 ALUNOS ESCOLHEM TAMBÉM ESSA OPÇÃO⚠: Com a Lista de Fornecedores Nacionais, você terá um tesouro com mais de 1000 contatos de WhatsApp dos maiores e mais confiáveis Fornecedores NACIONAIS 🇧🇷 Por apenas - R$ 47,00</p>
                            </div>
                        </div> -->
                    </div>
                    <div class="row" v-if="(checkoutType == 'stepByStep' && step == 3) || checkoutType == 'allSteps'">
                        <div class="col-md finish"
                            style="text-align: center;">
                            <button v-if="checkoutType == 'stepByStep'" @click="step = 2" class="go-back">Voltar</button>
                            <button type="submit"
                                class="button_finish mx-auto" :disabled="!validateStep(3)" @click.prevent="finishCheckout">
                                <img src="https://cdn.hoopay.com.br/images/checkout/secure_checkout_light.png"
                                    height="15" /> {{ payload.paymentMethod == 'pix' ? 'Gerar pix' : 'Finalizar'}}
                            </button>
                        </div>
                    </div>
                    <footer class="my-4">
                        <!-- <img width="100%" src="https://lh6.googleusercontent.com/wk4j62b7r9WSkSjBLtVk5SZBHa8r9oFgu_qC9gPT_JbMnUfA86VwXdEUZr-xyOKQvFTukY6Ato2wMU2axGkfA9hv73iEf0dOrsY2HTwBxdq5K3GcDHqeYT4x_TPROWTVAtPXYRBpeMb9SDb1_Q" > -->
                        <p>Hoopay está processando este pagamento para o vendedor {{  }}</p>
                        <p>Este site é protegido pelo reCAPTCHA do Google</p>
                        <p>Política de privacidade e Termos de serviço</p>
                        <p>* Parcelamento com acréscimo</p>
                        <p>Ao continuar, você concorda com os Termos de Com pra</p>
                    </footer>
                </div>
            </div>
        </div>
    </form>

    <MyModal :isOpen="isTransactionFeedbackModalOpen" :width="'420px'" :height="'230px'" :toggleModal="(v: boolean) => isTransactionFeedbackModalOpen = v">
        <div class="transaction-feedback">
            <h6>Transação não concluída</h6>
            <p>No momento não é possível concluir essa compra</p>
            <button @click="isTransactionFeedbackModalOpen = false">Ok, entendi</button>
        </div>
    </MyModal>

    <div class="expiry-time" v-if="false">
        <p>
            <strong>
                <span>07:23</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 24 24" fill="none">
                    <path d="M20.75 13.25C20.75 18.08 16.83 22 12 22C7.17 22 3.25 18.08 3.25 13.25C3.25 8.42 7.17 4.5 12 4.5C16.83 4.5 20.75 8.42 20.75 13.25Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 8V13" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9 2H15" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </strong>  
            Não perca essa oportunidade! Preço promocional acabará em breve..
        </p>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router'
import { vMaska } from "maska/vue"
import axiosClient from '@/api/axios'
import useValidation from '@/utils/useValidation'
import ChargePayloadInterface from '@/interfaces/ChargePayloadInterface'
import MyModal from '@/components/modal/MyModal.vue'
import router from '@/router/index'

const errorMessages = ref<Record<string, string | null>>({})
const route = useRoute()
const validation = useValidation()
const checkoutType = ref<'stepByStep' | 'allSteps'>('allSteps')
const step = ref<number>(1)
const openApplyCoupon = ref(route.query.coupon ? true : false)
const cardsQuantity = ref<number>(1) 
const address = ref<Partial<{neighborhood: string, district: string, number: number, street: string, complement: string, state: string, city: string, ibge: string}>>({ })
const isTransactionFeedbackModalOpen = ref(false)
const payload = ref<ChargePayloadInterface>({
    name: route.query.name as string,
    email: route.query.email as string,
    document: '',
    phoneNumber: '',
    paymentMethod: 'creditCard',
    ccards: [{ expiryMonth: undefined, expiryYear: undefined }, {}],
    cep: '',
    coupon: route.query.coupon as string,
})

const checkoutDetails = ref<Record<string, any>>({});

function validateStep(step: 1 | 2 | 3): boolean{
    const stepOneIsInvalid = (!payload.value.name || !validateEmail() || !validateDocument() || !payload.value.phoneNumber || payload.value.phoneNumber?.length < 16)
    const stepTwoIsInvalid = (!payload.value.cep || payload.value.cep?.length < 9 || errorMessages.value.cep) ? true : false
    let stepThreeIsInvalid = false

    if(cardsQuantity.value == 2 && payload.value.paymentMethod == 'creditCard'){
        stepThreeIsInvalid = payload.value.ccards.some((card: any) => (!card?.number || card.number.length !== 19 || !card?.holder || card.holder.length < 2 || !card.expiryMonth || !card.expiryYear || !card?.cvv || card.cvv.length < 3 || !card.installments))
    } else if(cardsQuantity.value == 1 && payload.value.paymentMethod == 'creditCard'){
        const card = payload.value.ccards[0];
        stepThreeIsInvalid = !card?.number || card.number.length !== 19 || !card?.holder || card.holder.length < 2 || !card.expiryMonth || !card.expiryYear || !card?.cvv || card.cvv.length < 3 || !card.installments;
    }

    if(step == 1){
        return !stepOneIsInvalid
    } else if(step == 2){
        return !stepOneIsInvalid && !stepTwoIsInvalid
    } else{
        return !stepOneIsInvalid && !stepTwoIsInvalid && !stepThreeIsInvalid
    }
}

const validateDocument = () => {
    const doc = payload.value.document;

    if (!doc) {
        errorMessages.value.document = 'Este campo é obrigatório';
        return;
    } else {
        // errorMessages.value.document = '';
    }

    if (doc.length <= 14) {
        if (!validation.isCPFValid(doc)) {
            errorMessages.value.document = 'CPF inválido';
        } else {
            errorMessages.value.document = '';
        }
    } else {
        if (!validation.isCNPJValid(doc)) {
            errorMessages.value.document = 'CNPJ inválido';
        } else {
            errorMessages.value.document = '';
        }
    }

    return !errorMessages.value.document ? true : false
};

function validateEmail(): boolean {
    let valid = true;
    const emailValue = payload.value.email?.trim() ?? '';
    const isEmpty = emailValue === '';
    const isInvalidFormat = !/\S+@\S+\.\S+/.test(emailValue);
    const hasInvalidChars = /[\(\)\<\>\,\;\:\\\/\"\[\]]/.test(emailValue);
    const hasSpaces = emailValue.includes(' ');

    if (true) {
        if (isEmpty) {
            errorMessages.value.email = "Este campo é obrigatório";
            valid = false;
        } else if (isInvalidFormat || hasInvalidChars || hasSpaces) {
            errorMessages.value.email = "Este email é inválido";
            valid = false;
        } else {
            errorMessages.value.email = "";
        }
    } 

    return valid;
}

function getCheckoutDetails(){
    axiosClient.get('/products/upsell', { params: { 'productId[]': route.query['productId[]'], 'qty[]': route.query['qty[]'] }})
    .then((res) => {
        console.log(res.data)
        checkoutDetails.value = res.data
        checkoutType.value = res.data.checkout.displayType
    })
    .catch(() => {
        router.push({ name: 'error' })
    })
}

function finishCheckout(): void{
    let payments: any

    if (payload.value.paymentMethod == 'creditCard') {
        payments = [{ type: 'creditCard', holder: payload.value.ccards[0].holder, number: payload.value.ccards[0].number, expirationDate: `${payload.value.ccards[0].expiryMonth!.toString().padStart(2, '0')}/${payload.value.ccards[0].expiryYear}`, cvv: payload.value.ccards[0].cvv, installments: payload.value.ccards[0].installments }] 
        if (cardsQuantity.value == 2) {
            payments.push({ type: 'creditCard', holder: payload.value.ccards[1].holder, number: payload.value.ccards[1].number, expirationDate: `${payload.value.ccards[1].expiryMonth!.toString().padStart(2, '0')}/${payload.value.ccards[1].expiryYear}`, cvv: payload.value.ccards[1].cvv, installments: payload.value.ccards[1].installments })
        } 
    } else if (payload.value.paymentMethod == 'pix') {
        payments = [{ type: 'pix' }]
    } else if (payload.value.paymentMethod == 'billet') {
        payments = [{ type: 'billet' }]
    }
    
    axiosClient.post('/charge', {
        url: window.location.href,
        src: route.query.src,
        customer: {
            email: payload.value.email,
            name: payload.value.name,
            phoneNumber: payload.value.phoneNumber,
            document: payload.value.document.replace(/\D/g, ''),
        },
        ...(payload.value.cep && payload.value.cep.trim() !== '' && { address: { 
            neighborhood: address.value.neighborhood, 
            district: address.value.district, 
            streetNumber: address.value.number, 
            street: address.value.street, 
            complement: address.value.complement, 
            state: address.value.state, 
            city: address.value.city, 
            zipcode: payload.value.cep, 
            // codeIbge: address.value.ibge, 
        }}),
        products: checkoutDetails.value.products.map((product: any) => ({ variantUUID: product.variantUUID, quantity: product.quantity })),
        payments,
    })
    .then((res) => {
        if(res.data.payment.hasErrors){
            isTransactionFeedbackModalOpen.value = true
        } else {
            if (payload.value.paymentMethod == 'creditCard' && checkoutDetails.value.thankYou.pageCreditCard) {
                window.location.href = checkoutDetails.value.thankYou.pageCreditCard
            } else if (payload.value.paymentMethod == 'pix' && checkoutDetails.value.thankYou.pagePix) {
                window.location.href = checkoutDetails.value.thankYou.pagePix
            } else if (payload.value.paymentMethod == 'billet' && checkoutDetails.value.thankYou.pageBillet) {
                window.location.href = checkoutDetails.value.thankYou.pageBillet
            } else {
                router.push({ name: 'thank-you' })
            }
        }
    })
    .catch(() => {
        isTransactionFeedbackModalOpen.value = true
    })
}

onMounted(() => {
    getCheckoutDetails()
    // window.JS_store = "L1VOvGVAeJZD62e";
    // window.JS_checkout_id = "PyE2ZyKwa4JgqRb";
    // window.JS_mobile = false;
    // window.JS_payment_done_boleto_url = null;
    // window.JS_payment_done_card_url = null;
    // window.JS_payment_done_pix_url = null;
    // window.JS_payment_done_analysing_url = null;
    // window.JS_total_value = "147.00";
    // window.JS_discount_coupon = "";
    // window.JS_payment_boleto = 0;
    // window.JS_payment_pix = 1;
    // window.JS_payment_credit_card = 1;
    // window.JS_automatic_discount_bank_slip = 0;
    // window.JS_automatic_discount_credit_card = 0;
    // window.JS_automatic_discount_pix = 0;
    // window.JS_content_pixels = {
    //     "value": "147.00",
    //     "currency": "BRL",
    //     "contents": "[{\"id\":\"NDr8gmlM0LEGBmj\",\"quantity\":1}]",
    //     "content_ids": "[{\"id\":\"NDr8gmlM0LEGBmj\"}]",
    //     "ecomm_prodid": [{"id": "NDr8gmlM0LEGBmj"}],
    //     "num_items": 1,
    //     "content_type": "product",
    //     "checkoutId": "PyE2ZyKwa4JgqRb"
    // };
    // window.JS_checkout_type = 2;
    // window.JS_checkout_template = 2;
    // window.JS_pre_selected_installment = 12;
    // window.JS_pixels_data = [];
    // window.JS_email_required = 1;
    // window.JS_log = "";
    // window.JS_has_physical_product = false;
    // window.JS_document_type = 3;
    // window.JS_original_subtotal = 14700;
    // window.JS_can_change_product_amount = true;
    // window.JS_require_dependencies = true;
    // window.JS_default_credit_card_gateway_id = 32;
    // window.JS_countdown_time = 10;
    // window.JS_countdown_timer_finished_message = "Seu tempo acabou, adquira seu acesso agora mesmo.";
})
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cardo:wght@700&family=Roboto&display=swap");

$primary-color: v-bind('checkoutDetails.checkout?.primaryColor');
$secondary-color: v-bind('checkoutDetails.checkout?.secondaryColor');
$header-background-color: v-bind('checkoutDetails.checkout?.headerBackgroundColor');
$content-ackground-color: v-bind('checkoutDetails.checkout?.contentBackgroundColor');

body {
    font-family: "Inter", sans-serif; /* Added a fallback font */
    font-size: 0.9rem;
    background: $content-ackground-color;
    max-width:100%
}

*{
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
}

a {
    text-decoration: none;
}

button{
    background:unset;
    border:unset
}

@keyframes enterContent{
    0%{
        display: flex;
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.enter-content-animation{
    animation: enterContent ease .4s
}

.text-primary {
    color: $primary-color
}

.expiry-time{
    position:fixed;
    background: rgba(238, 179, 18, 0.8);
    left:0;
    width:100%;
    top: 0;
    padding: 1rem;
    color: #fff;
    text-align: center;
    font-weight: 600;
    p{
        display: flex;
        justify-content: center;
        align-items: center;
        gap:0.5rem;
        margin:0;
        strong{
            font-size:1.6rem;
            display: flex;
            align-items: center;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none; /* Added non-prefixed version */
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield; /* Added non-prefixed version */
}

.check{
    input{
      display: none;
      &:checked ~ span{
        background: #079F72;
        border-color: #079F72;
      }
    }
    span{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      min-width: 24px;
      height: 24px;
      min-height: 24px;
      border-radius: 8px;
      border: 1px solid #6F6F6F;
      transition: border-color .2s, background .2s;
      cursor: pointer;
    }
  }

select{
    &.form-control{
        -webkit-appearance: inherit !important;
        -moz-appearance: inherit !important;
        appearance:auto !important; /* Added non-prefixed version */
    }
}

form {
    margin: auto;
    position: relative;
    .side-social-proof{
        right: -50%
    }
}


nav{
    padding:2rem 0 0 0;
    ul{
        display:flex;
        align-items:center;
        width:100%;
        padding:unset;
        li{
            list-style: none;
            button{
                border:5px solid #dddddd;
                border-radius: 50%;
                padding:1rem;
                width:1rem;
                height:1rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.active{
                button{
                    border-color:$primary-color; 
                    color: $primary-color;
                }
            }
        }
        span{
            display:block;
            height:8px;
            width:100%;
            background: #dddddd;
            &.active{
                background: $primary-color
            }
        }
    }
}

.container {
    max-width: 800px;
    margin: 0 auto;
}

.section_title {
    color: #444444;
    font-size: 1rem;
    margin: 15px 0;
}

header {
    background: #f6f9f8;
    padding: 30px 10px;
    margin-bottom: 6rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

header .items_list {
    margin-top: 40px;
    margin-bottom: -98px;
    background: #fff;
    border-radius: 4px;
    padding: 0 30px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

header thead th,
header tfoot th {
    text-align: left;
    font-size: 0.8rem;
    background: #f6f9f8;
    color: #a8a8b3;
    font-weight: 500;
    padding: 10px;
}

header tfoot th {
    background: #FFF;
    border-top: 2px #F6F9F8 solid;
}

.button_discount_coupon {
    font-size: 0.9rem;
    width: 100%;
    margin: 10px 0;
    padding: 15px;
    text-align: center;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    background: $primary-color;
    color: #444444;
    border: 2px solid $primary-color;
    transition: all .2s ease
}

.button_discount_coupon:hover {
    border: 2px solid $secondary-color;
}

.input_discount_coupon {
    margin-top: 0 !important;
    position: relative !important;
    flex: 1 1 auto !important;
    width: 1% !important;
    min-width: 0 !important;

}

.button_discount_coupon_apply {
    background: $primary-color;
    color: #444444;
    border: 1px solid #ced4da !important;
}

.button_discount_coupon_apply:hover,
.button_discount_coupon:hover {
    background: $secondary-color;
    color: #444444;
}

.button_method_payment {
    width: 100%;
    padding: 15px;
    border: 2px solid #f2f2f4;
    background: #fff;
    color: #000;
    text-align: center;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.button_method_payment.selected,
.button_method_payment.active {
    margin-bottom: -3px;
    box-shadow: 0px 2px 1px 0px $primary-color;
}

@media (min-width: 769px) {

    .button_method_payment.selected,
    .button_method_payment.active {
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0px -2px 1px 0px $primary-color;
    }
}

@media (max-width: 768px) {

    .button_method_payment.selected,
    .button_method_payment.active {
        border: 2px solid $primary-color;
    }
}

.button_finish {
    width: 100%;
    max-width: 200px;
    padding: 20px;
    border: 0;
    background: $primary-color;
    color: #FFF;
    text-align: center;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

#amount_total {
    color: #000;
}

.content {
    max-width: 100%;
    border: 1px solid #ddd;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    margin-top: 2rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    background: #FFF;
    animation: enterContent .4s ease;
    button{
        background:$primary-color;
        padding:1rem;
        border-radius:.5rem;
        color: #fff;
        width:100%;
        margin:1rem 0;
        transition: all 0.2s;
        &:disabled{
            background:#f5f5f5;
            color: #6F6F6F;
            cursor: not-allowed; 
        }
        &.go-back{
            background: #f5f5f5;
            border:1px solid #f5f5f5;
            color: #6F6F6F;
        }
    }
    p{
        &.error{
            color: #d10000;
            font-size:12px
        }
    }
    .finish{
        display:flex;
        gap:1rem;
        button{
            width:50%;
            max-width:50%
        }
    }
    .order-bump{
        border:1px dashed #ccc;
        padding: 1rem;
        border-radius: .5rem;
        display: flex;
        flex-direction: column;
        gap:1rem;
        &__content{
            display: grid;
            grid-template-columns: 5% 1fr 75%;
            gap: 1rem; 
            img{
                width:7rem;
                height:7rem;
            }
            p{
                color: #777
            }
        }
    }
}

label {
    margin-bottom: 15px;
    position: relative;
    width: 100%;
}

label input,
label select {
    width: 100% !important;
    margin-top: 24px !important;
    outline: none !important;
    padding: 1.3rem .9rem .4rem .9rem !important;
    font-size: .9rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #212529 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

label select {
    /*background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");*/
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 15px;
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: #198754 !important;
    padding-right: calc(1.5em + .75rem) !important;
    /*background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e) !important;*/
    background-repeat: no-repeat !important;
    background-position: right calc(.375em + .1875rem) center !important;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem) !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + .75rem) !important;
    /*background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e) !important;*/
    background-repeat: no-repeat !important;
    background-position: right calc(.375em + .1875rem) center !important;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem) !important;
}



label input::placeholder,
label select::placeholder {
    opacity: 0;
}

label span {
    position: absolute;
    top: 9px;
    left: 15px;
    transform: translateY(30px);
    font-size: 0.9rem;
    transition-duration: 300ms;
    color: #606060;
    pointer-events: none;
}

label:focus-within>span,
label input:not(:placeholder-shown)+span,
label select:not(:placeholder-shown)+span {
    font-size: 0.8rem;
    top: 25px;
    color: #a8a8b3;
    transform: translateY(0px);
}

.discount_coupon label span {
    top: -13px;
}

.discount_coupon label:focus-within>span,
.discount_coupon label input:not(:placeholder-shown)+span {
    top: 3px;
}

.payment_box {
    width: 100%;
    padding: 10px;
    border: 2px solid #f2f2f4;
    background: #fff;
    border-radius: 4px;
    font-size: 0.75rem;
    color: #444444;
}

.payment_box_title {
    font-size: 1rem;
    font-weight: 600;
}

.tabs {
    background: #fff;
    position: relative;
    margin-bottom: 50px;
}

.tabs>input,
.tabs>span {
    width: 50%;
    height: 60px;
    line-height: 60px;
    position: absolute;
    top: 0;
}

.tabs>input {
    cursor: pointer;
    filter: alpha(opacity=0);
    opacity: 0;
    position: absolute;
    z-index: 99;
}

.tabs>span {
    background: #f0f0f0;
    text-align: center;
    overflow: hidden;
}

.tabs>span i,
.tabs>span {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.tabs>input:hover+span {
    background: rgba(255, 255, 255, .1);
}

.tabs>input:checked+span {
    background: #fff;
}

.tabs>input:checked+span,
.tabs>input:hover+span {
    color: #3498DB;
}

#tab-1,
#tab-1+span {
    left: 0;
}

#tab-2,
#tab-2+span {
    left: 50%;
}

.tab-content section {
    width: 100%;
    display: none;
}

.tab-content section h1 {
    margin-top: 15px;
    font-size: 100px;
    font-weight: 100;
    text-align: center;
}

#tab-1:checked~.tab-content #tab-item-1 {
    display: block;
}

#tab-2:checked~.tab-content #tab-item-2 {
    display: block;
}

.effect-3 .line {
    background: #3498DB;
    width: 50%;
    height: 4px;
    position: absolute;
    top: 56px;
}

#tab-1:checked~.line {
    left: 0;
}

#tab-2:checked~.line {
    left: 50%;
}

.transaction-feedback {
    padding: 1.45rem;
    h6 {
        font-weight: bold;
        font-size: 1.3rem;
        text-align: center;
    }
    p {
        color: #949494;
        padding: 1rem 0;
        text-align: center;
    }
    button {
        background: rgb(77, 177, 77);
        color: #fff;
        padding: .7rem 1rem;
        border-radius: 1rem;
        width: 100%;
    }
}

.panel-default {
    border-color: #ddd;
}

.panel {
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.panel.with-nav-tabs .nav-tabs {
    border-bottom: none;
}


.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}


.nav-tabs>li {
    float: left;
    margin-bottom: -1px;
}

.nav>li {
    position: relative;
    cursor: pointer;
    display: block;
}

@media screen and (max-width: 600px){
    header{
        margin-bottom:0rem;
        padding:20px 10px 0 10px
    }
    .content {
        .payment-method__boxes {
            grid-template-columns: 1fr !important;
        }
    }
}
</style>