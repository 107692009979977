<template>
    <div class="success-page">
      <div class="success-header">
        <div class="success-icon">
          <div class="icon-circle">
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.75 11.9999L10.58 14.8299L16.25 9.16992" stroke="#00B84C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </div>
        </div>
        <h1>Pagamento efetuado!</h1>
      </div>
  
      <div class="content-container">
        <div class="info-card">
          <div class="info-section">
            <div class="section-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="16" x2="12" y2="12"></line>
                <line x1="12" y1="8" x2="12.01" y2="8"></line>
              </svg>
            </div>
            <div class="section-content">
              <h3>Informações do Pedido</h3>
              <p>Número: {{ charge?.orderUUID }}</p>
              <p>Produto: {{ charge?.products.map((p) => p.title).join(', ') }}</p>
              <p v-if="charge">Data: {{ new Date(charge.createdAt).toLocaleDateString() }}</p>
            </div>
          </div>
  
          <div class="info-section">
            <div class="section-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
            </div>
            <div class="section-content">
              <h3>Dados do Cliente</h3>
              <p v-if="charge?.customer.name">Nome: {{ charge?.customer.name }}</p>
              <p v-if="charge?.customer.document.number">CPF: {{ formatDocument(String(charge?.customer.document.number)) }}</p>
              <p v-if="charge?.customer.email">Email: {{ charge?.customer.email }}</p>
              <p v-if="charge?.customer.phone">Telefone: {{ formatPhoneNumber(charge?.customer.phone.phoneNumber) }}</p>
            </div>
          </div>
  
          <div v-if="address && address.city" class="info-section">
            <div class="section-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                <circle cx="12" cy="10" r="3"></circle>
              </svg>
            </div>
            <div class="section-content">
              <h3>Endereço de Entrega</h3>
              <p>{{ address.street }}, {{ address.number }}</p>
              <p>{{ address.complement }}</p>
              <p>{{ address.neighborhood }} - {{ address.city }}/{{ address.state }}</p>
            </div>
          </div>
  
          <div v-if="checkoutDetails.support.email || checkoutDetails.support.phone || checkoutDetails.support.website" class="info-section">
            <div class="section-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
              </svg>
            </div>
            <div class="section-content">
              <h3>Suporte</h3>
              <p v-if="checkoutDetails.support.email">Email: {{ checkoutDetails.support.email }}</p>
              <p v-if="checkoutDetails.support.phone">Tel: {{ formatPhoneNumber(checkoutDetails.support.phone) }}</p>
              <p v-if="checkoutDetails.support.website">Site: {{ checkoutDetails.support.website }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  import { charge } from '@/global/states/ChargeState'
  import useFormat from '@/utils/format/useFormat'
  
  const { formatDocument, formatPhoneNumber } = useFormat()
  const checkoutDetails = localStorage.getItem('checkoutDetails') ? JSON.parse(localStorage.getItem('checkoutDetails')!) : undefined
  const address: Partial<{neighborhood: string, district: string, number: number, street: string, complement: string, state: string, city: string, ibge: string}> = localStorage.getItem('address') ? JSON.parse(localStorage.getItem('address')!) : undefined
  </script>
  
  <style>
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  
  .success-page {
    min-height: 100vh;
    background: #fff;
    font-family: 'Inter', sans-serif;
  }
  
  .success-header {
    background: #00B84C;
    padding: 3rem 1rem;
    text-align: center;
    color: white;
  }
  
  .success-icon {
    margin-bottom: 1.5rem;
  }
  
  .icon-circle {
    width: 80px;
    height: 80px;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
  }
  
  .icon-circle svg {
    color: #00B84C;
  }
  
  .check-icon {
    position: absolute;
    bottom: -4px;
    right: -4px;
    background: #00B84C;
    border-radius: 50%;
    padding: 4px;
    color: white;
  }
  
  .success-header h1 {
    font-size: 1.75rem;
    font-weight: 600;
    margin: 0;
  }
  
  .content-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  
  .info-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  }
  
  .info-section {
    display: flex;
    padding: 1.5rem;
    border-bottom: 1px solid #eee;
  }
  
  .info-section:last-child {
    border-bottom: none;
  }
  
  .section-icon {
    margin-right: 1rem;
    color: #00B84C;
  }
  
  .section-content {
    flex: 1;
  }
  
  .section-content h3 {
    font-size: 1rem;
    font-weight: 600;
    color: #333;
    margin: 0 0 0.75rem 0;
  }
  
  .section-content p {
    margin: 0.5rem 0;
    color: #666;
    font-size: 0.95rem;
    line-height: 1.5;
  }
  
  @media (max-width: 640px) {
    .success-header {
      padding: 2rem 1rem;
    }
    
    .success-header h1 {
      font-size: 1.5rem;
    }
    
    .content-container {
      padding: 1rem;
    }
    
    .info-section {
      padding: 1.25rem;
    }
  }
  </style>