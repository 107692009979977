<template>
    <div v-if="charge" class="pix-container">
      <div class="pix-header">
        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="me-2">
            <path d="M20.9498 14.55L14.5598 20.94C13.1598 22.34 10.8598 22.34 9.44978 20.94L3.05977 14.55C1.65977 13.15 1.65977 10.85 3.05977 9.44001L9.44978 3.05C10.8498 1.65 13.1498 1.65 14.5598 3.05L20.9498 9.44001C22.3498 10.85 22.3498 13.15 20.9498 14.55Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.25 6.25L17.75 17.75" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.75 6.25L6.25 17.75" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg> -->
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="me-2">
            <path d="M16.5098 5.01L14.5498 3.05C13.1498 1.65 10.8498 1.65 9.44977 3.05L7.48977 5.01C7.09977 5.4 7.09977 6.04 7.48977 6.43L11.2998 10.24C11.6898 10.63 12.3198 10.63 12.7098 10.24L16.5198 6.43C16.8998 6.04 16.8998 5.4 16.5098 5.01Z" fill="#171717"/>
            <path d="M5.01 7.49L3.05 9.45C1.65 10.85 1.65 13.15 3.05 14.55L5.01 16.51C5.4 16.9 6.03 16.9 6.42 16.51L10.23 12.7C10.62 12.31 10.62 11.68 10.23 11.29L6.43 7.49C6.04 7.1 5.4 7.1 5.01 7.49Z" fill="#171717"/>
            <path d="M20.95 9.45L18.99 7.49C18.6 7.1 17.97 7.1 17.58 7.49L13.77 11.3C13.38 11.69 13.38 12.32 13.77 12.71L17.58 16.52C17.97 16.91 18.6 16.91 18.99 16.52L20.95 14.56C22.35 13.15 22.35 10.85 20.95 9.45Z" fill="#171717"/>
            <path d="M7.49005 18.99L9.45005 20.95C10.8501 22.35 13.1501 22.35 14.5501 20.95L16.5101 18.99C16.9001 18.6 16.9001 17.97 16.5101 17.58L12.7001 13.77C12.3101 13.38 11.6801 13.38 11.2901 13.77L7.48005 17.58C7.10005 17.96 7.10005 18.6 7.49005 18.99Z" fill="#171717"/>
        </svg>
        <h1>Pagamento via PIX</h1>
      </div>
  
      <div class="pix-content">
        <div class="pix-charges" v-for="(pixCharge, index) in charge?.payment.charges" :key="index">
          <div class="status-card">
            <div class="status-icon">
              <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" stroke="#26C9AD" stroke-width="2"/>
                <path d="M12 7V12" stroke="#26C9AD" stroke-width="2" stroke-linecap="round"/>
                <circle cx="12" cy="16" r="1" fill="#26C9AD"/>
              </svg>
            </div>
            <div class="status-text">
              <h2>Aguardando pagamento</h2>
              <p>Realize o pagamento para concluir sua compra</p>
            </div>
          </div>
  
          <div class="qr-code-container">
            <div class="qr-code-wrapper">
              <img :src="`data:image/png;base64,${pixCharge.pixQrCode}`" alt="QR Code PIX" class="qr-code" />
            </div>
  
            <!-- <div class="expiry-time">
              <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#FF6B6B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 4.5V8L10 10" stroke="#FF6B6B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span>Válido por 30 minutos</span>
            </div> -->
          </div>
  
          <div class="pix-code-container">
            <label for="pixCode" class="pix-code-label">Código PIX</label>
            <div class="pix-code-input-group">
              <input 
                id="pixCode" 
                type="text" 
                :value="pixCharge.pixPayload" 
                readonly 
                class="pix-code-input" 
              />
              <button 
                class="copy-icon-button" 
                @click="copyPixCode(pixCharge.pixPayload!)"
                :class="{ 'copied': isCopied }"
              >
                <svg v-if="!isCopied" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15 2H9C8.44772 2 8 2.44772 8 3V5C8 5.55228 8.44772 6 9 6H15C15.5523 6 16 5.55228 16 5V3C16 2.44772 15.5523 2 15 2Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg v-else width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 6L9 17L4 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          </div>
  
          <button class="payment-button" @click="consultPix(true)">
            <span>Já fiz o pagamento</span>
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 12H19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 5L19 12L12 19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
  
          <div class="divider">
            <span>Como pagar com PIX</span>
          </div>
  
          <div class="steps-container">
            <div class="step">
              <div class="step-number">1</div>
              <div class="step-content">
                <h3>Abra o app do seu banco</h3>
                <p>Acesse o aplicativo do seu banco ou instituição financeira</p>
              </div>
            </div>
            
            <div class="step">
              <div class="step-number">2</div>
              <div class="step-content">
                <h3>Escolha a opção PIX</h3>
                <p>Selecione a opção PIX no menu principal do aplicativo</p>
              </div>
            </div>
            
            <div class="step">
              <div class="step-number">3</div>
              <div class="step-content">
                <h3>Escaneie o QR Code ou cole o código</h3>
                <p>Utilize a câmera para ler o QR Code ou cole o código PIX copiado</p>
              </div>
            </div>
            
            <div class="step">
              <div class="step-number">4</div>
              <div class="step-content">
                <h3>Confirme o pagamento</h3>
                <p>Verifique os dados e confirme a transação</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BackdropLoader v-else-if="!$route.query.orderUUID" :zIndex="10" />
    <GetOrderComponent />
  </template>
  
  <script lang="ts" setup>
  import { onBeforeUnmount, onMounted, ref } from 'vue'
  import { charge } from '@/global/states/ChargeState'
  import { toast } from 'vue3-toastify'
  import router from '@/router/index'
  import axiosClient from '@/api/axios';
  import BackdropLoader from '@/components/loader/BackdropLoader.vue';
  import GetOrderComponent from './components/GetOrderComponent.vue';
  
  let pixInterval: number
  const isCopied = ref(false)
  
  function copyPixCode(pixPayload: string) {
      navigator.clipboard.writeText(pixPayload)
      .then(() => {
          isCopied.value = true
          toast.success('Código PIX copiado com sucesso!', { autoClose: 1000 })
          
          // Reset copy state after animation
          setTimeout(() => {
              isCopied.value = false
          }, 2000)
      })
      .catch(err => {
          toast.error('Erro ao copiar o código')
      });
  }
  
  function consultPix(showPaymentUndentifiedToast = false) {
      if (charge.value?.orderUUID) {
          axiosClient.get('/pix/consult/' + charge.value?.orderUUID)
              .then((res) => {
                  if (res.data.result.status === 'paid') {
                      const checkoutDetails = localStorage.getItem('checkoutDetails') ? JSON.parse(localStorage.getItem('checkoutDetails')!) : undefined
  
                      if (checkoutDetails?.thankYou?.pagePix) {
                          window.location.href = checkoutDetails?.thankYou?.pagePix
                      } else {
                          router.push({ name: 'thank-you' })
                      }
                  } else if (showPaymentUndentifiedToast) {
                      toast.error('Pagamento não identificado')
                  }
              })
              .catch(() => router.push({ name: 'error' }))
      } else {
          router.push({ name: 'error' })
      }
  }
  
  onMounted(() => {
      if (charge.value) {
          consultPix()
          pixInterval = setInterval(consultPix, 5000)
      }
  })
  
  onBeforeUnmount(() => {
      clearInterval(pixInterval)
  })
  </script>
  
  <style>
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
  
  :root {
    --primary-color: #26C9AD;
    --primary-hover: #1EB69C;
    --secondary-color: #7B68EE;
    --secondary-hover: #6A56E8;
    --text-color: #333333;
    --text-light: #666666;
    --text-lighter: #888888;
    --border-color: #E5E7EB;
    --background-light: #F9FAFB;
    --danger-color: #FF6B6B;
    --white: #FFFFFF;
    --success-color: #22C55E;
  }
  
  .pix-container {
    font-family: 'Inter', sans-serif;
    max-width: 600px;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: var(--text-color);
    background-color: var(--white);
  }
  
  .pix-header {
    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 24px;
  }
  
  .pix-header h1 {
    color: var(--text-color);
    font-size: 22px;
    font-weight: 600;
    margin: 0;
  }

  .pix-header svg {
    fill: var(--text-color);
    stroke: var(--text-color);
  }
  
  .pix-content {
    flex: 1;
    padding: 0 20px 24px;
  }
  
  .pix-charges {
    display: flex;
    flex-direction: column;
    max-width: 460px;
    margin: 0 auto;
  }
  
  .status-card {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 20px;
    background-color: rgba(38, 201, 173, 0.05);
    border: 1px solid rgba(38, 201, 173, 0.2);
    border-radius: 12px;
    margin-bottom: 28px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.03);
  }
  
  .status-text h2 {
    color: var(--text-color);
    font-size: 17px;
    font-weight: 600;
    margin: 0 0 6px;
  }
  
  .status-text p {
    color: var(--text-light);
    font-size: 14px;
    margin: 0;
    line-height: 1.4;
  }
  
  .qr-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background-light);
    border-radius: 12px;
    padding: 44px 20px;
    /* margin-bottom: 24px; */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.03);
  }
  
  .qr-code-wrapper {
    display: flex;
    justify-content: center;
    /* margin-bottom: 16px; */
  }
  
  .qr-code {
    width: 200px;
    height: 200px;
    border-radius: 8px;
    padding: 8px;
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  }
  
  .expiry-time {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--danger-color);
    font-size: 14px;
    font-weight: 500;
    background-color: rgba(255, 107, 107, 0.08);
    padding: 8px 16px;
    border-radius: 20px;
  }
  
  .pix-code-container {
    margin-bottom: 24px;
  }
  
  .pix-code-label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    color: var(--text-color);
  }
  
  .pix-code-input-group {
    display: flex;
    position: relative;
  }
  
  .pix-code-input {
    width: 100%;
    padding: 14px;
    padding-right: 50px;
    border-radius: 8px;
    border: 1px solid var(--border-color);
    background-color: var(--background-light);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: var(--text-color);
    transition: all 0.2s ease;
  }
  
  .pix-code-input:focus {
    outline: none;
    border-color: var(--secondary-color);
    box-shadow: 0 0 0 2px rgba(123, 104, 238, 0.1);
  }
  
  .copy-icon-button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 48px;
    border: none;
    background-color: transparent;
    color: var(--text-lighter);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  
  .copy-icon-button:hover {
    color: var(--secondary-color);
  }
  
  .copy-icon-button.copied {
    color: var(--success-color);
    animation: pulse 0.5s ease-in-out;
  }
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
  }
  
  .payment-button {
    display: flex;
    width: 100%;
    padding: 16px;
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-bottom: 12px;
    border: none;
    background-color: var(--primary-color);
    color: var(--white);
    justify-content: center;
    align-items: center;
    gap: 8px;
    box-shadow: 0 4px 12px rgba(38, 201, 173, 0.2);
  }
  
  .payment-button:hover {
    background-color: var(--primary-hover);
    transform: translateY(-1px);
    box-shadow: 0 6px 16px rgba(38, 201, 173, 0.25);
  }
  
  .payment-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 8px rgba(38, 201, 173, 0.15);
  }
  
  .divider {
    position: relative;
    text-align: center;
    margin: 32px 0 24px;
  }
  
  .divider::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: var(--border-color);
  }
  
  .divider span {
    position: relative;
    background-color: var(--white);
    padding: 0 16px;
    color: var(--text-light);
    font-size: 14px;
    font-weight: 500;
  }
  
  .steps-container {
    margin-bottom: 32px;
    background-color: var(--background-light);
    border-radius: 12px;
    padding: 20px;
  }
  
  .step {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
  }
  
  .step:last-child {
    margin-bottom: 0;
  }
  
  .step-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: rgba(38, 201, 173, 0.1);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 14px;
    flex-shrink: 0;
  }
  
  .step-content h3 {
    font-size: 15px;
    font-weight: 600;
    margin: 0 0 6px;
    color: var(--text-color);
  }
  
  .step-content p {
    font-size: 14px;
    margin: 0;
    color: var(--text-light);
    line-height: 1.5;
  }
  
  @media (max-width: 480px) {
    .pix-container {
      max-width: 100%;
    }
    
    .pix-content {
      padding: 0 16px 24px;
    }
    
    .qr-code {
      width: 180px;
      height: 180px;
    }
  }
  </style>