import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import * as Sentry from "@sentry/vue";

const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://a1a823190a41b2435def4f6d53c248df@o4504584095334400.ingest.us.sentry.io/4508964701339648"
});

app.use(router).mount('#app')
